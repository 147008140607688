












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RangeFilter extends Vue {
  public filterFrom = "";
  public filterTo = "";

  submit(): void {
    let option = {
      filterFrom: this.filterFrom,
      filterTo: this.filterTo,
    };
    this.$emit("change", option);
  }
}
