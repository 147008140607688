




































































import { Watch, Component, Vue, Prop } from "vue-property-decorator";
import { topRoundedRect, bottomRoundedRect } from "@/helper/charts";
import NoDataHtml from "@/components/common/NoDataHtml.vue";
import { getGermanNum } from "@/helper/utils";
import store from "@/store";
declare const d3: any;

@Component({ components: { NoDataHtml } })
export default class VerticalSingleBar extends Vue {
  @Prop() id!: string;
  @Prop() dataId!: string;

  chart: any = {};
  hideAxiesX: any = false;
  noDataFound: any = true;

  get chartData() {
    return {
      calcs: store.getters.currentCalcs,
      yearSums: store.getters.currentYearSums,
      rangeStep: store.getters.rangeStep,
      resize: store.getters.resize,
      umsDetailKunden: store.getters.umsDetailKunden,
      materialDetail: store.getters.materialDetail,
    };
  }

  mounted() {
    this.onDataChange();
  }

  @Watch("chartData")
  onDataChange() {
    const colors = [
      "var(--primary-dark)",
      "var(--primary-light)",
      "var(--primary)",
    ];
    if (this.chartData.yearSums && this.chartData.yearSums[this.dataId]) {
      this.noDataFound = false;
      this.chartVerticalSingleBar(
        Object.values(this.chartData.yearSums[this.dataId]),
        Object.keys(this.chartData.yearSums[this.dataId]),
        "",
        this.id,
        false,
        null,
        null,
        null
      );
    }

    if (this.chartData.calcs && this.chartData.calcs["BWA"]) {
      this.noDataFound = false;
      if (this.id === "chart_betriebsergebnis_1300") {
        this.chartVerticalSingleBar(
          [
            this.chartData.calcs["BWA"]["total"]["1080"],
            this.chartData.calcs["BWA"]["total"]["1280"],
            this.chartData.calcs["BWA"]["total"]["1300"],
          ],
          ["Rohertrag", "Gesamtkosten", "Betriebsergebnis"],
          null,
          this.id,
          true,
          true,
          colors,
          true
        );
      } else if (this.id === "chart_ebitda_pie") {
        this.chartVerticalSingleBar(
          [
            this.chartData.calcs["BWA"]["total"]["1240"],
            this.chartData.calcs["BWA"]["total"]["1380"],
            this.chartData.calcs["BWA"]["total"]["EBITDA"],
          ],
          ["Abschreibungen", "EBIT - Vorläufiges Ergebnis", "EBITDA"],
          null,
          this.id,
          true,
          true,
          colors,
          true
        );
      } else if (this.id === "chart_ebit_three_bar") {
        this.chartVerticalSingleBar(
          [
            this.chartData.calcs["BWA"]["total"]["1345"],
            this.chartData.calcs["BWA"]["total"]["1355"],
            this.chartData.calcs["BWA"]["total"]["1380"],
          ],
          [
            "Ergebnis vor Steuern",
            "Steuern Einkünfte und Erträge",
            "EBIT - Vorläufiges Ergebnis",
          ],
          null,
          this.id,
          true,
          true,
          colors,
          true
        );
      }
    }

    if (this.id === "chart_material_bar" && this.chartData.materialDetail) {
      this.noDataFound = false;
      var d: any = [];
      var l: any = [];
      this.chartData.materialDetail.yearlist.forEach((item: any) => {
        d.push(item[1]);
        l.push(item[0]);
      });
      this.chartVerticalSingleBar(d, l, null, this.id, false, null, null, null);
    }

    if (this.id === "chart_uk_jahre" && this.chartData.umsDetailKunden) {
      this.noDataFound = false;
      var d0 = [];
      var labels = [];
      this.chartData.umsDetailKunden.yearlist.sort(function(a: any, b: any) {
        return a[0] - b[0];
      });
      for (var i = 0; i < this.chartData.umsDetailKunden.yearlist.length; i++) {
        labels.push(this.chartData.umsDetailKunden.yearlist[i][0]);
        d0.push(this.chartData.umsDetailKunden.yearlist[i][1]);
      }
      this.chartVerticalSingleBar(
        d0,
        labels,
        "Umsatzerlöse",
        "chart_uk_jahre",
        true,
        null,
        null,
        null
      );
    }
  }

  chartVerticalSingleBar(
    d0: any,
    labels: any,
    colt: any,
    id: any,
    changeBarWidth: any,
    useLabel: any,
    colors: any,
    hideXAxis: any
  ) {
    if (!d0 || !d0.length) {
      return;
    }
    this.chart = {};
    let el: any = document.getElementById(id);
    if (typeof changeBarWidth === "undefined" || changeBarWidth === null) {
      changeBarWidth = false;
    }
    this.chart.svg_width = el.clientWidth;
    this.chart.svg_height = el.clientHeight;

    var margin = { top: 20, right: 15, bottom: 30, left: 45 };
    var data = [];
    var maxVal = 0;
    var minVal = 0;
    if (this.chart.svg_width <= 160) {
      margin.bottom = 40;
    }
    if (hideXAxis) {
      this.hideAxiesX = true;
      margin.top = 15;
      margin.bottom = 15;
    }
    if (d0.length > 4 && id != "chart_uk_jahre") {
      d0 = d0.slice(1, d0.length);
      labels = labels.slice(1, labels.length);
    }
    if (changeBarWidth && !useLabel) {
      if (store.getters.years && store.getters.selectedYear) {
        for (
          var j = store.getters.years[0][0];
          j <= store.getters.selectedYear[0];
          j++
        ) {
          if (
            (id != "chart_uk_jahre" && j < store.getters.selectedYear[0]) ||
            id == "chart_uk_jahre"
          ) {
            labels[j - store.getters.years[0][0]] = j;
            d0[j - store.getters.years[0][0]] =
              d0[j - store.getters.years[0][0]] || 0;
          }
        }
      }
    }
    for (var i: any = 0; i < d0.length; i++) {
      data.push({
        year: labels[i],
        val: d0[i],
        color: colors ? colors[i] : null,
      });
      if (d0[i] > maxVal) {
        maxVal = d0[i];
      }
      if (d0[i] < minVal) {
        minVal = d0[i];
      }
    }
    var width = el.clientWidth - margin.left - margin.right;
    var height = el.clientHeight - margin.top - margin.bottom;
    this.chart.transform = `translate( ${margin.left} , ${margin.top} )`;
    var x: any = d3
      .scaleBand()
      .rangeRound([0, width])
      .paddingInner(d0.length == 1 ? 0 : changeBarWidth ? 0.3 : 0.6)
      .align(0.1);
    var y = d3.scaleLinear().rangeRound([height, 0]);
    var maxBarWidth = 100;
    x.domain(
      data.map(function(d) {
        return d.year;
      })
    );
    y.domain([minVal, maxVal]).nice();
    d3.select("#" + id + "_axisY")
      .attr("class", "axis axis-y")
      .attr("transform", "translate(-10, 0)")
      .call(
        d3
          .axisLeft(y)
          .ticks(2, "s")
          .tickSize(-width)
      );
    this.chart.paths = [];
    this.chart.texts = [];
    data.forEach((d: any) => {
      if (y(d.val) - y(0) < 0) {
        this.chart.paths.push({
          path_d: topRoundedRect(
            x.bandwidth() > maxBarWidth
              ? x(d.year) + (x.bandwidth() - maxBarWidth) / 2
              : x(d.year),
            y(Math.max(0, d.val)),
            x.bandwidth() > maxBarWidth ? maxBarWidth : x.bandwidth(),
            Math.abs(y(d.val) - y(0)),
            4
          ),
          fill: d.color || "var(--primary)",
        });
      } else if (y(d.val) - y(0) > 0) {
        this.chart.paths.push({
          path_d: bottomRoundedRect(
            x.bandwidth() > maxBarWidth
              ? x(d.year) + (x.bandwidth() - maxBarWidth) / 2
              : x(d.year),
            y(Math.max(0, d.val)),
            x.bandwidth() > maxBarWidth ? maxBarWidth : x.bandwidth(),
            Math.abs(y(d.val) - y(0)),
            4
          ),
          fill: d.color || "var(--primary)",
        });
      }
      this.chart.texts.push({
        x: x(d.year) + x.bandwidth() / 2,
        y:
          d.val >= 0
            ? y(Math.max(0, d.val)) - 5
            : y(Math.max(0, d.val)) + Math.abs(y(d.val) - y(0)) + 15,
        text:
          typeof d.val !== "number"
            ? ""
            : getGermanNum(
                Math.abs(d.val / 1000000) >= 1
                  ? (d.val / 1000000).toFixed(1) + "M"
                  : (d.val / 1000).toFixed(1) + "k"
              ),
        fill: "var(--primary-dark)",
        text_anchor: "middle",
      });
    });
    this.chart.tip = [];
    if (colors && labels) {
      for (var l: any = 0; l < d0.length; l++) {
        this.chart.tip.push({
          color: colors[l],
          label: labels[l],
          value: (d0[l] / 1000).toFixed() + "k",
        });
      }
    }
    if (!hideXAxis) {
      this.hideAxiesX = false;
      const xAxis = d3
        .select("#" + id + "_axisX")
        .attr("class", "axis axis-x")
        .attr("transform", "translate(0," + (height + 20) + ")")
        .call(d3.axisBottom(x));
      if (this.chart.svg_width <= 160) {
        xAxis
          .selectAll("text")
          .attr("dx", "-.4em")
          .attr("dy", "5px")
          .attr("transform", "rotate(-25)");
      } else {
        xAxis
          .selectAll("text")
          .attr("dx", "0")
          .attr("dy", "0")
          .attr("transform", "rotate(0)");
      }
    }
  }
}
