
























import Widget from "@/components/common/Widget.vue";
import VerticalSingleBar from "@/components/charts/VerticalSingleBar.vue";
import Vue from "vue";

import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import store from "@/store";
import api from "@/api";

@Component({
  components: {
    Widget,
    VerticalSingleBar,
  },
})
export default class DashboardItemWrapper extends Vue {
  @Prop() id!: string;
  @Prop() dprops!: any;
  @Prop({ default: false }) disableFavorite!: boolean;

  get isDashboard() {
    return this.$router.currentRoute.name === "dashboard";
  }

  get isFavorite() {
    const fav: string[] = store.getters.favorites;
    return fav.includes(this.id);
  }

  handleStarClick() {
    const favs = [...store.getters.favorites];
    if (this.isFavorite) {
      if (
        confirm(
          "Sind Sie sicher, dass Sie dieses Widget aus ihren Favoriten entfernen wollen?"
        )
      ) {
        this.removeIdFromDLayout();
        store.dispatch(
          "updateFavorites",
          favs.filter((v) => v !== this.id)
        );
      }
    } else {
      this.addIdToDLayout();
      store.dispatch("updateFavorites", [...favs, this.id]);
    }
    api.saveUserSettings();
  }

  removeIdFromDLayout() {
    const _dlayout = [...store.state.dlayout];

    (store.state.dlayout as any[]).forEach((layout, index) => {
      const itemIndex = (layout.items as any[]).findIndex(
        (v) => v.id == this.id
      );

      if (index > -1) {
        _dlayout[index].items.splice(itemIndex, 1);
      }
    });
  }

  addIdToDLayout() {
    const _dlayout = [...store.state.dlayout];

    (store.state.dlayout as any[]).forEach((layout, index) => {
      const lastItem = layout.items[layout.items.length - 1];

      let newItem;
      if (lastItem) {
        newItem = {
          id: this.id,
          x: 0,
          y: lastItem.y + (lastItem.height || 1),
          ...this.dprops,
        };
      } else {
        newItem = {
          id: this.id,
          x: 0,
          y: 0,
          ...this.dprops,
        };
      }
      if (layout.breakpoint === "xs") {
        newItem.width = 1;
        newItem.height = 1;
      }
      _dlayout[index].items = [..._dlayout[index].items, newItem];
    });
  }
}
