






























































































import { Watch, Component, Vue } from "vue-property-decorator";
import Widget from "@/components/common/Widget.vue";
import DataTable from "@/components/table/DataTable.vue";
import SelectFilter from "@/components/common/SelectFilter.vue";
import RangeFilter from "@/components/common/RangeFilter.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import api from "../../api";
import store from "../../store";
import {
  germanZahl,
  b64DecodeUnicode,
  b64EncodeUnicode,
  isValidJSON,
} from "../../helper/utils";

const monthstrs = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const TABLE_COLUMNS = [
  {
    label: "VorlNr",
    prop: "vorlnr",
    sortable: true,
  },
  {
    label: "Monat",
    prop: "monat",
    sortable: true,
  },
  {
    label: "BsNr",
    prop: "bsnr",
    sortable: true,
  },
  {
    label: "BelegDat",
    prop: "belegdat",
    sortable: true,
  },
  {
    label: "KtoNr",
    prop: "ktonr",
    sortable: true,
  },
  {
    label: "Umsatz",
    prop: "umsatz",
    sortable: true,
  },
  {
    label: "SollHaben",
    prop: "sollhaben",
    sortable: true,
  },
  {
    label: "GegKto",
    prop: "gegkto",
    sortable: true,
  },
  {
    label: "Beleg1",
    prop: "beleg1",
    sortable: true,
  },
  {
    label: "Beleg2",
    prop: "beleg2",
    sortable: true,
  },
  {
    label: "BuchText",
    prop: "buchtext",
    sortable: true,
  },
];

let minUmsatz = 100000;
let maxUmsatz = 0;

@Component({
  components: {
    Widget,
    DataTable,
    SelectFilter,
    RangeFilter,
    DashboardItemWrapper,
  },
})
export default class BuchungssaetzeTable extends Vue {
  public static id = "BuchungssaetzeTable";
  public static dprops = { width: 4, height: 4, minWidth: 4, minHeight: 4 };
  get _id() {
    return BuchungssaetzeTable.id;
  }
  get _dprops() {
    return BuchungssaetzeTable.dprops;
  }
  public tableColums = TABLE_COLUMNS;
  public tableRows: Array<any> = [];

  // State
  public firstInit = false;
  public selectedClient_0 = null;
  public selectedClient_1 = null;
  public selectedYear_0 = null;

  // Sort
  public totalItems = 0;
  public perPage = 25;
  public perPageList = [10, 25, 50, 100];
  public offsetPage = 0;
  public orderby = "vorlnr";
  public orderDirection = "asc";

  // Filter
  public filterKtonr: Array<any> | null = null;
  public filterKontobezeichnungen: Array<any> | null = null;
  public filterBuchtext: Array<any> | null = null;
  public filterBbsnr: Array<any> | null = null;

  public filterKtonrList: Array<any> = [];
  public filterKontobezeichnungenList: Array<any> = [];
  public filterBuchtextList: Array<any> = [];
  public filterBbsnrList: Array<any> = [];

  get selectedYear() {
    return store.getters.selectedYear;
  }

  get selectedClient() {
    return store.getters.selectedClient;
  }

  get requestData() {
    return {
      selectedClient: this.selectedClient,
      selectedYear: this.selectedYear,
    };
  }

  // get filterKtonrList() {
  //   if (
  //     store.getters.currentCalcs &&
  //     store.getters.currentCalcs["BUCHDD"] &&
  //     store.getters.currentCalcs["BUCHDD"]["ktonr"]
  //   ) {
  //     return store.getters.currentCalcs["BUCHDD"]["ktonr"];
  //   }
  //   return [];
  // }

  // get filterKontobezeichnungenList() {
  //   if (
  //     store.getters.currentCalcs &&
  //     store.getters.currentCalcs["BUCHDD"] &&
  //     store.getters.currentCalcs["BUCHDD"]["kontobezeichnungen"]
  //   ) {
  //     return store.getters.currentCalcs["BUCHDD"]["kontobezeichnungen"];
  //   }
  //   return [];
  // }

  // get filterBuchtextList() {
  //   if (
  //     store.getters.currentCalcs &&
  //     store.getters.currentCalcs["BUCHDD"] &&
  //     store.getters.currentCalcs["BUCHDD"]["buchungstexte"]
  //   ) {
  //     return store.getters.currentCalcs["BUCHDD"]["buchungstexte"];
  //   }
  //   return [];
  // }

  // get filterBbsnrList() {
  //   if (
  //     store.getters.currentCalcs &&
  //     store.getters.currentCalcs["BUCHDD"] &&
  //     store.getters.currentCalcs["BUCHDD"]["bsnr"]
  //   ) {
  //     return store.getters.currentCalcs["BUCHDD"]["bsnr"];
  //   }
  //   return [];
  // }

  @Watch("requestData")
  updateRequestData(val: any, oldVal: any) {
    if (val.selectedClient && val.selectedYear) {
      let updateClient =
        !oldVal.selectedClient ||
        val.selectedClient[0] !== oldVal.selectedClient[0] ||
        val.selectedClient[1] !== oldVal.selectedClient[1];
      let updateYear =
        !oldVal.selectedYear || val.selectedYear[0] !== oldVal.selectedYear[0];
      if (updateClient || updateYear) {
        this.perPage = 25;
        this.offsetPage = 0;
        this.orderby = "vorlnr";
        this.orderDirection = "asc";
        this.filterKtonr = null;
        this.filterKontobezeichnungen = null;
        this.filterBuchtext = null;
        this.filterBbsnr = null;
        if (this.firstInit) {
          this.fetchData();
        } else {
          this.fetchFirstInit();
        }
      }
    }
  }

  changeSortDirection(val: any) {
    this.orderDirection = val;
    this.getBuchungssaetze();
  }

  changeSortProperty(val: any) {
    this.orderby = val;
    this.orderDirection = "asc";
    this.getBuchungssaetze();
  }

  changePerPage(val: any): void {
    if (val) {
      let offsetPage = val - 1;
      if (offsetPage !== this.offsetPage) {
        this.offsetPage = offsetPage;
        this.getBuchungssaetze();
      }
    }
  }

  changefilter(val: any, key?: string) {
    this.offsetPage = 0;
    if (key === "perPage") {
      this.getBuchungssaetze();
    } else {
      this.fetchData();
    }
  }

  getFilters() {
    let filter: any = {};
    if (this.filterKtonr && this.filterKtonr.length) {
      filter["ktonr"] = this.filterKtonr;
    }
    if (this.filterKontobezeichnungen && this.filterKontobezeichnungen.length) {
      filter["kontobezeichnung"] = this.filterKontobezeichnungen;
    }
    if (this.filterBuchtext && this.filterBuchtext.length) {
      filter["buchtext"] = this.filterBuchtext;
    }
    if (this.filterBbsnr && this.filterBbsnr.length) {
      filter["bsnr"] = this.filterBbsnr;
    }
    return filter;
  }

  getBuchungssaetze() {
    let parms: any = {};
    parms["limit"] = this.perPage;
    parms["offset"] = this.offsetPage * this.perPage;
    parms["orderby"] = this.orderby;
    parms["orderdir"] = this.orderDirection;

    let filter: any = this.getFilters();

    // Apply filter only if anything is set
    if (Object.keys(filter).length) {
      parms["filter"] = filter;
    }

    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    return api
      .getBuchungssaetze(this.selectedClient, this.selectedYear, parms)
      .then((resp: any) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        let d = resp.data;

        if (d && d.length > 0) {
          this.totalItems = d[0][11];
        }

        // Dataset postprocessing: Proper formatting of €
        for (let idx in d) {
          if (d[idx][5] > maxUmsatz) {
            maxUmsatz = d[idx][5];
          }
          if (d[idx][5] < minUmsatz) {
            minUmsatz = d[idx][5];
          }
          d[idx][5] = germanZahl(d[idx][5]);
        }

        // Dataset postprocessing: Month
        for (let idx in d) {
          d[idx][1] = this.selectedYear[0] + "-" + monthstrs[d[idx][1] - 1];
        }

        let tableRows: Array<any> = [];
        d.forEach((z: any) => {
          let item: any = {};
          z.forEach((x: any, i: any) => {
            let colItem = this.tableColums[i];
            if (colItem) {
              item[colItem.prop] = x;
            }
          });
          tableRows.push(item);
        });

        this.tableRows = [...tableRows];
      })
      .catch((err) => {
        this.tableRows = [];
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  getBuchungssaetzeFilterFirstInit() {
    return api
      .getBuchungssaetzeFilter(this.selectedClient, this.selectedYear, {})
      .then((resp: any) => {
        let d = resp.data;
        let currentCalcs = Object.assign({}, this.$store.getters.currentCalcs);
        // if selected keep using current data, otherwise use new list received from api
        if (!this.filterKtonr || !this.filterKtonr.length) {
          this.filterKtonrList = d["ktonr"];
          if (
            currentCalcs &&
            currentCalcs["BUCHDD"] &&
            currentCalcs["BUCHDD"]["ktonr"]
          ) {
            currentCalcs["BUCHDD"]["ktonr"] = d["ktonr"];
          }
        }
        if (
          !this.filterKontobezeichnungen ||
          !this.filterKontobezeichnungen.length
        ) {
          this.filterKontobezeichnungenList = d["kontobezeichnung"];
          if (
            currentCalcs &&
            currentCalcs["BUCHDD"] &&
            currentCalcs["BUCHDD"]["kontobezeichnungen"]
          ) {
            currentCalcs["BUCHDD"]["kontobezeichnungen"] =
              d["kontobezeichnung"];
          }
        }
        if (!this.filterBuchtext || !this.filterBuchtext.length) {
          this.filterBuchtextList = d["buchtext"];
          if (
            currentCalcs &&
            currentCalcs["BUCHDD"] &&
            currentCalcs["BUCHDD"]["buchungstexte"]
          ) {
            currentCalcs["BUCHDD"]["buchungstexte"] = d["buchtext"];
          }
        }
        if (!this.filterBbsnr || !this.filterBbsnr.length) {
          this.filterBbsnrList = d["bsnr"];
          if (
            currentCalcs &&
            currentCalcs["BUCHDD"] &&
            currentCalcs["BUCHDD"]["bsnr"]
          ) {
            currentCalcs["BUCHDD"]["bsnr"] = d["bsnr"];
          }
        }

        this.$store.dispatch("updateCurrentCalcs", currentCalcs);
      })
      .catch((err) => {
        store.dispatch("updateCurrentCalcs", null);
      });
  }

  getBuchungssaetzeFilter() {
    let parameters: any = {};

    let filter: any = this.getFilters();

    // Apply filter only if anything is set
    if (Object.keys(filter).length) {
      parameters["filter"] = filter;
    }

    return api
      .getBuchungssaetzeFilter(
        this.selectedClient,
        this.selectedYear,
        parameters
      )
      .then((resp: any) => {
        let d = resp.data;
        let currentCalcs = Object.assign({}, this.$store.getters.currentCalcs);
        // if selected keep using current data, otherwise use new list received from api
        if (!this.filterKtonr || !this.filterKtonr.length) {
          this.filterKtonrList = d["ktonr"];
          if (
            currentCalcs &&
            currentCalcs["BUCHDD"] &&
            currentCalcs["BUCHDD"]["ktonr"]
          ) {
            currentCalcs["BUCHDD"]["ktonr"] = d["ktonr"];
          }
        }
        if (
          !this.filterKontobezeichnungen ||
          !this.filterKontobezeichnungen.length
        ) {
          this.filterKontobezeichnungenList = d["kontobezeichnung"];
          if (
            currentCalcs &&
            currentCalcs["BUCHDD"] &&
            currentCalcs["BUCHDD"]["kontobezeichnungen"]
          ) {
            currentCalcs["BUCHDD"]["kontobezeichnungen"] =
              d["kontobezeichnung"];
          }
        }
        if (!this.filterBuchtext || !this.filterBuchtext.length) {
          this.filterBuchtextList = d["buchtext"];
          if (
            currentCalcs &&
            currentCalcs["BUCHDD"] &&
            currentCalcs["BUCHDD"]["buchungstexte"]
          ) {
            currentCalcs["BUCHDD"]["buchungstexte"] = d["buchtext"];
          }
        }
        if (!this.filterBbsnr || !this.filterBbsnr.length) {
          this.filterBbsnrList = d["bsnr"];
          if (
            currentCalcs &&
            currentCalcs["BUCHDD"] &&
            currentCalcs["BUCHDD"]["bsnr"]
          ) {
            currentCalcs["BUCHDD"]["bsnr"] = d["bsnr"];
          }
        }

        this.$store.dispatch("updateCurrentCalcs", currentCalcs);

        // save state
        var filters: any = [];
        if (this.filterKtonr && this.filterKtonr.length) {
          this.filterKtonr.forEach((z) => {
            filters.push({
              p: "konto_strs",
              v: z,
            });
          });
        }
        if (
          this.filterKontobezeichnungen &&
          this.filterKontobezeichnungen.length
        ) {
          this.filterKontobezeichnungen.forEach((z) => {
            filters.push({
              p: "ktobez_strs",
              v: z,
            });
          });
        }
        if (this.filterBuchtext && this.filterBuchtext.length) {
          this.filterBuchtext.forEach((z) => {
            filters.push({
              p: "buchtext_strs",
              v: z,
            });
          });
        }
        if (this.filterBbsnr && this.filterBbsnr.length) {
          this.filterBbsnr.forEach((z) => {
            filters.push({
              p: "bsnr_strs",
              v: z,
            });
          });
        }

        var url = "";
        if (filters.length > 0) {
          url =
            "#buchungssaetze?filter=" +
            b64EncodeUnicode(JSON.stringify(filters));
          window.history.replaceState(
            {
              page: "buchungssaetze",
            },
            "buchungssaetze",
            url
          );
        } else {
          window.history.replaceState(
            { page: "buchungssaetze" },
            "buchungssaetze",
            "#buchungssaetze?filter="
          );
        }
      })
      .catch((err) => {
        store.dispatch("updateCurrentCalcs", null);
      });
  }

  fetchFirstInit() {
    this.getBuchungssaetzeFilterFirstInit().then(() => {
      this.firstInit = true;
      let konto_strs = localStorage.getItem("buchungssaetze_filter_konto");
      var filters = window.location.hash.split("filter=")[1]
        ? window.location.hash.split("filter=")[1]
        : null;
      if (konto_strs) {
        let kontoFilter = JSON.parse(konto_strs);
        this.filterKtonr = kontoFilter;
        this.fetchData();
        localStorage.removeItem("buchungssaetze_filter_konto");
      } else if (filters) {
        var parameters = b64DecodeUnicode(filters);
        if (isValidJSON(parameters)) {
          var data = JSON.parse(b64DecodeUnicode(filters));
          let konto_strs: any = [],
            ktobez_strs: any = [],
            buchtext_strs: any = [],
            bsnr_strs: any = [];

          // TODO: this may be refactored for efficiency
          data.forEach((filterOption: any) => {
            if (filterOption.p == "konto_strs") konto_strs.push(filterOption.v);
            if (filterOption.p == "ktobez_strs")
              ktobez_strs.push(filterOption.v);
            if (filterOption.p == "buchtext_strs")
              buchtext_strs.push(filterOption.v);
            if (filterOption.p == "bsnr_strs") bsnr_strs.push(filterOption.v);
          });

          this.filterKtonr = konto_strs;
          this.filterKontobezeichnungen = ktobez_strs;
          this.filterBuchtext = buchtext_strs;
          this.filterBbsnr = bsnr_strs;
        }
        this.fetchData();
      } else {
        this.fetchData();
      }
    });
  }

  fetchData() {
    return Promise.all([
      this.getBuchungssaetze(),
      this.getBuchungssaetzeFilter(),
    ]);
  }

  clearAllFilters() {
    //Init default values
    this.perPage = 25;
    this.offsetPage = 0;
    this.orderby = "vorlnr";
    this.orderDirection = "asc";
    this.filterKtonr = null;
    this.filterKontobezeichnungen = null;
    this.filterBuchtext = null;
    this.filterBbsnr = null;
    this.fetchData();
  }

  mounted() {
    if (this.requestData.selectedClient && this.requestData.selectedYear) {
      this.fetchFirstInit();
    }
  }
}
