















import { Component, Vue } from "vue-property-decorator";
import TopBar from "@/components/common/TopBar.vue";
import BuchungssaetzeTable from "@/components/DashboardItems/BuchungssaetzeTable.vue";

@Component({
  components: { TopBar, BuchungssaetzeTable },
})
export default class Buchungssaetze extends Vue {}
